import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-black small text-center text-white-50">
      <div className="container">                
        <p>hello@wrkrs.com.au</p>
        <p>© {new Date().getFullYear()}{' '}<a href="https://alphalecapital.com/">AlphaLe Capital</a></p>
      </div>
    </footer>
  );
}
